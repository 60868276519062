<template>
  <div class="page-wrap departure-situation" style="justify-content: flex-end;">
    <!-- 表单搜索 start -->
    <div class="form-wrap">
      <div class="seach">
        <div class="inputs">
          <el-date-picker
            type="daterange"
            clearable
            v-model="feedback_time"
            start-placeholder="反馈时间起"
            end-placeholder="反馈时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => addTimeChange(val, 'feedback_time')"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-date-picker
            type="daterange"
            clearable
            v-model="create_time"
            start-placeholder="提交时间起"
            end-placeholder="提交时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => addTimeChange(val, 'create_time')"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.reply_name"
            clearable
            placeholder="反馈人姓名"
          >
          </el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.title"
            clearable
            placeholder="商品名称"
          >
          </el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.first_cid"
            clearable
            filterable
            placeholder="请选择一级品类"
          >
            <el-option
              v-for="item of searchdata.first_category_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.cid"
            clearable
            filterable
            placeholder="请选择二级品类"
          >
            <el-option
              v-for="item of searchdata.second_category_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.apply_mobile"
            clearable
            placeholder="请输入提交人电话"
          >
          </el-input>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.apply_name"
            clearable
            placeholder="请输入提交人账号"
          >
          </el-input>
        </div>
        <div class="inputs">
          <el-select v-model="formData.type" clearable placeholder="请选择类型">
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in typeList"
              :key="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.state"
            clearable
            placeholder="请选择反馈状态"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in stateList"
              :key="index"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <el-button @click="onSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            type="warning"
            :disabled="!tableData.length"
            @click="exportHandle"
            icon="el-icon-download"
            >导 出</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="table-wrap">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column prop="apply_time" align="center" label="提交时间">
        </el-table-column>
        <el-table-column prop="apply_name" align="center" label="提交人账户">
        </el-table-column>
        <el-table-column prop="apply_mobile" align="center" label="提交人电话">
        </el-table-column>
        <el-table-column prop="op_name" align="center" label="反馈人姓名">
        </el-table-column>
        <el-table-column prop="op_mobile" align="center" label="反馈人电话">
        </el-table-column>
        <el-table-column prop="title" align="center" label="商品名称">
        </el-table-column>
        <el-table-column prop="first_cid_name" align="center" label="商品品类">
          
          <template slot-scope="scope">
{{ scope.row.first_cid_name }}-{{ scope.row.cid_name }}
          </template>
        </el-table-column>
        <el-table-column prop="type_txt" align="center" label="类型">
        </el-table-column>
        <el-table-column prop="remark" align="center" label="提交人内容">
        </el-table-column>
        <el-table-column prop="apply_img_list" align="center" label="上传素材">
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.apply_img_list && scope.row.apply_img_list.length > 0
              "
            >
              <el-image
                class="kv pointer"
                :src="scope.row.apply_img_list[0]"
                :preview-src-list="scope.row.apply_img_list"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="op_remark" align="center" label="反馈内容">
        </el-table-column>
        <el-table-column prop="op_img_list" align="center" label="反馈素材">
          <template slot-scope="scope">
            <div
              v-if="scope.row.op_img_list && scope.row.op_img_list.length > 0"
            >
              <el-image
                class="kv pointer"
                :src="scope.row.op_img_list[0]"
                :preview-src-list="scope.row.op_img_list"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="state_txt"
          align="center"
          label="反馈状态"
          min-width="100"
        >
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { BASE } from "@/api";
import { postMemberStarList } from "@/api/general/user-submission.js";
import { STATE_ENUM, TYPE_ENUM } from "./utils/enum";

export default {
  name: "user-demand-submission",
  data() {
    return {
      stateList: Object.values(STATE_ENUM),
      typeList: Object.values(TYPE_ENUM),
      feedback_time: [],
      create_time: [],
      loading: false,
      disabledExport: false, // 导出禁用
      formData: {
        page: 1, //	number	页码
        pageSize: 20, //	number	页大小
        apply_time_start: "", //提交时间起
        apply_time_end: "", //提交时间止
        reply_time_start: "", // 反馈时间起
        reply_time_end: "", // 反馈时间止
        reply_name: "", // 反馈人姓名
        apply_name: "", // 提交人账号
        apply_mobile: "", // 提交人电话
        type: "", // 类型
        state: "", // 反馈状态
        cid: "",
        first_cid: "",
        title:''
      }, // 表单参数
      tableData: [], // 城市仓列表
      total: 0,
      searchdata: "",
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    // 获取下拉
    getSearchData() {
      this.$api.general.searchdata().then((res) => {
        console.log(res);
        this.searchdata = res.data;
      });
    },
    // 初始化
    onInitData() {
      this.getSearchData();
      this.hqlist();
    },
    // 获取列表
    async hqlist() {
      this.loading = true;
      try {
        const res = await postMemberStarList(this.formData);
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.total;
      } catch (error) {
        console.log(error, "postMemberStarList");
      }
    },
    /**
     * 导出
     */
    exportHandle() {
      let url = `${
        BASE.PRO1
      }/member/star/list/export?token=${sessionStorage.getItem("token")}`;
      for (let key in this.formData) {
        if (key != "page" && key != "pageSize") {
          url = url + `&${key}=${this.formData[key]}`;
        }
      }
      window.open(url, "_blank");
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      this.feedback_time = [];
      this.create_time = [];
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.hqlist();
    },
    /**
     * 搜索表单
     */
    onSearch() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
    // 修改时间
    addTimeChange(val, name) {
      if (name == "feedback_time") {
        if (val) {
          this.formData.reply_time_start = val[0];
          this.formData.reply_time_end = val[1];
        } else {
          this.formData.reply_time_start = "";
          this.formData.reply_time_end = "";
        }
      } else {
        if (val) {
          this.formData.apply_time_start = val[0];
          this.formData.apply_time_end = val[1];
        } else {
          this.formData.apply_time_start = "";
          this.formData.apply_time_end = "";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-image {
  width: 70px;
  height: 70px;
}

.from-wraps {
  /** 修改element基础样式 */
  .el-select .el-input.is-disabled .el-input__inner {
    color: #333333;
  }

  .el-input.is-disabled .el-input__inner {
    color: #333333;
  }
}

.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      //   justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .inputs {
        display: flex;
        align-items: center;
        .divname {
          width: 100px;
        }
        // width: 207px;
        // margin-right: 8px;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        padding: 10px 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
  .base-margin {
    margin: 0 10px;
    white-space: nowrap;
  }
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
}
</style>
