import axios from "../http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-心愿单-列表
 * @param {*} params
 * @returns
 */
export function postMemberStarList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/member/star/list`,
    data: params,
  });
}
