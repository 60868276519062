/** 反馈状态 */
export const STATE_ENUM = {
  1: {
    label: "未回复",
    value: 1,
  },
  2: {
    label: "已过期",
    value: 2,
  },
  3: {
    label: "已回复",
    value: 3,
  },
};

/**  类型  */
export const TYPE_ENUM = {
  1: {
    label: "要新品",
    value: 1,
  },
  2: {
    label: "要低价",
    value: 2,
  },
};
